<script setup>
import InfoStat from "~/components/InfoStat.vue";

const props = defineProps({
    tweet: Object,
});

const tweet = computed(() => {
    return props.tweet;
});

const state = computed(() => {
    return tweet.value?.rating?.state;
});

const selectedCurrency = ref('USD');

const campaignCurrencies = computed(() => {
    return prepareCurrencies(tweet.value?.rating?.reward);
});

const selectedCurrencyAmount = computed(() => {
    return campaignCurrencies.value[selectedCurrency.value];
});

const rewardVisible = computed(() => {

    if (["evaluating", "not-eligible", "not-rewarded", "expired", "expired-bonus"].includes(state.value)) {
        return false;
    }

    return true;

});

const computedRewardCaption = computed(() => {
    if (["rewarded", "paid", "eligible-bonus", "paid-bonus"].includes(state.value)) {
        return "Final Reward";
    }
    return "Estimated Reward";
});

const computedRewardValue = computed(() => {
    if (["rewarded", "paid", "eligible-bonus", "paid-bonus"].includes(state.value)) {
        return selectedCurrencyAmount.value;
    }
    return '~' + selectedCurrencyAmount.value;
});

onMounted(() => {
    selectedCurrency.value = Object.keys(campaignCurrencies.value).find(i => i !== "USD");
});


</script>

<template>
    <div class="flex items-center justify-between my-8">
        <TweetState class="py-1.5 px-3 rounded-3xl" :state="tweet.rating?.state" :rating="tweet?.rating?.ratingScore"/>
        <div v-if="rewardVisible" class="select-gray-wrapper">
            <select v-model="selectedCurrency" class="select-gray">
                <option v-for="currency in Object.keys(campaignCurrencies)" :value="currency">{{ currency }}</option>
            </select>
        </div>
    </div>

    <div v-if="rewardVisible" class="flex gap-4 items-center justify-between mb-10">
        <div class="flex-auto">
            <InfoStat :value="transformToPercentage(tweet?.rating?.ratingScore ?? 0)" :dark-questionmarks="true" label="Post Relevance" tooltip="Post Relevance is calculated based on alignemnt of the post with this campaign."/>
        </div>
        <div class="flex-auto">
            <InfoStat v-if="selectedCurrency == 'USD'" :dark-questionmarks="true" :value="selectedCurrencyAmount" :suffix="selectedCurrency" :label="computedRewardCaption" tooltip="Rewards are calculated based on the post relevance, performance and engagement of creators within this campaign."/>
            <InfoStat v-else :value="computedRewardValue" :dark-questionmarks="true" :suffix="selectedCurrency" :label="computedRewardCaption" tooltip="Rewards are calculated based on the post relevance, performance and engagement of creators within this campaign."/>
        </div>
    </div>
</template>