<template>
    <div class="flex flex-col">
        <div class="mb-2 lg:mb-4 text-2xl lg:text-5xl font-bold">{{ value ?? 0 }}
            <span v-if="suffix" class="text-lg lg:text-2xl uppercase ml-0 lg:ml-2">{{ suffix }}</span>
        </div>
        <div class="flex gap-4">
            <div class="hidden lg:block text-black/50 font-semibold">{{ label }}</div>
            <div class="block lg:hidden text-black/50 font-semibold">{{ phoneLabel ?? label }}</div>
            <div class="hidden lg:block">
                <button v-if="tooltip" class="btn text-black/50 w-5 h-5 p-0" :class="[darkQuestionmarks ? 'questionmark-light' : 'btn-light btn-light-border']">
                    ?
                    <tippy to="parent">
                        <div class="">{{ tooltip }}</div>
                    </tippy>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoColumn',
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
            required: true
        },
        phoneLabel: {
            type: String,
            required: true
        },
        suffix: {
            type: String,
            required: false
        },
        tooltip: {
            type: String,
            required: true
        },
        darkQuestionmarks: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>