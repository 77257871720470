<script setup>
const props = defineProps({
    state: {
        type: String,
    },
    rating: {
        type: Number,
        default: 0
    }
})

const ratingScrore = computed(() => {
    return props.rating ?? 0
})

const currentState = computed(() => {

    let state = props.state;
    let postRelevanceSentence = "";

    if (ratingScrore.value > 0) {
        let rating = transformToPercentage(ratingScrore.value ?? 0);
        postRelevanceSentence = "Post Relevance: " + rating + "<br/><br/>";
    }

    if (state === 'eligible') {
        return {
            label: "Eligible",
            colors: "bg-black text-white border-transparent",
            tooltip: postRelevanceSentence + "This post is eligible for a reward. but it needs to be claimed before the campaign ends.<br/><br/>5 of your best eligible posts will be rewardedat the end of the campaign every week.",
        }
    }

    if (state === 'eligible-bonus') {
        return {
            label: "Eligible",
            colors: "bg-black text-white border-transparent",
            tooltip: "This post is eligible for a reward.<br/>It needs to be claimed before it expires.",
        }
    }

    if (state === 'claimed') {
        return {
            label: "Eligible",
            colors: "bg-black text-white border-transparent",
            tooltip: postRelevanceSentence + "This post is eligible for a reward.<br/><br/>5 of your best eligible posts will be rewarded at the end of the campaign every week.",
        }
    }

    if (state === 'not-eligible') {
        return {
            label: "Not Eligible",
            colors: "bg-gray-300 text-black border-transparent",
            tooltip: postRelevanceSentence + "This post is not eligible for a reward, due to a low post relevance."
        }
    }

    if (state === 'rewarded') {
        return {
            label: "Rewarded",
            colors: "bg-white text-black border-gray-200",
            tooltip: postRelevanceSentence + "This post had been rewarded as one of your top 5 performing posts."
        }
    }

    if (state === 'not-rewarded') {
        return {
            label: "Not Rewarded",
            colors: "bg-gray-200 text-black border-transparent",
            tooltip: postRelevanceSentence + "Although this post passed the campaign criteria, it was not your top 5 performing post within that week, so it was not rewarded."
        }
    }

    if (state === 'paid') {
        return {
            label: "Paid",
            colors: "bg-white text-black border-gray-200",
            tooltip: postRelevanceSentence + "This post had been rewarded as one of your top 5 performing posts."
        }
    }

    if (state === 'paid-bonus') {
        return {
            label: "Paid",
            colors: "bg-white text-black border-gray-200",
            tooltip: postRelevanceSentence + "This post had been rewarded."
        }
    }

    if (state === 'expired') {
        return {
            label: "Expired",
            colors: "bg-gray-200 text-black border-transparent",
            tooltip: "The campaign has already ended without any claims on this post. Therefore it has not been included in the evaluation period."
        }
    }

    if (state === 'expired-bonus') {
        return {
            label: "Expired",
            colors: "bg-gray-200 text-black border-transparent",
            tooltip: "This post has not been claimed in time."
        }
    }

    if (state === 'evaluating') {
        return {
            label: "Evaluating",
            colors: "bg-gray-200 text-black border-transparent",
            tooltip: "Post is currently being evaluated for eligibility. It can take up to 24 hours."
        }
    }

    return {
        label: state,
        colors: "bg-gray-200 text-black border-transparent"
    }

})

</script>

<template>
    <button class="flex-shrink-0 hover:bg-purple-light hover:border-purple-light hover:text-black rounded-2xl px-2 leading-5 inline-flex items-center gap-2" style="border-width: 1px" :class="currentState.colors">
        <IconsEvangelistIcon v-if="state == 'evaluating'" class="rotating-object"/>
        {{ currentState.label }}
        <tippy v-if="currentState?.tooltip" to="parent" placement="top-start">
            <div class="" v-html="currentState.tooltip"></div>
        </tippy>
    </button>
</template>
