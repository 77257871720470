<script setup>
import HeartIcon from "~/components/Icons/HeartIcon.vue";
import EyeIcon from "~/components/Icons/EyeIcon.vue";
import ClaimButton from "~/components/ClaimButton.vue";
import TweetDetailReward from "~/components/TweetDetailReward.vue";

const props = defineProps({
    tweetId: {
        type: String,
        required: true,
    },
});

const tweetId = computed(() => {
    return props.tweetId;
});

const tweet = ref({});
const loading = ref(true);

function getTweet() {
    loading.value = true;
    apiService.getTweet(tweetId.value).then((response) => {
        let tweetData = response.data;

        if (!tweetData?.rating?.state) {
            tweetData.rating = {
                state: "evaluating",
                reward: 0,
            }
        }

        tweet.value = tweetData;
    }).catch(e => {
        errorMessage(e);
    }).finally(() => {
        loading.value = false;
    });
}

const campaign = computed(() => {
    return tweet.value?.campaign ?? null;
});

const state = computed(() => {
    return tweet.value?.rating?.state;
});

const eligibleCampaigns = computed(() => {
    return tweet.value?.suggestedCampaigns ?? [];
});

const tweetContent = computed(() => {
    return formatTweetText(tweet.value?.content ?? "");
});


getTweet();

const tweetLinkCopied = ref(false);

function copyTweetLink() {
    let tweetId = tweet.value?.id;
    let url = `https://twitter.com/i/web/status/${tweetId}`;

    navigator.clipboard.writeText(url).then(() => {
        tweetLinkCopied.value = true;
        setTimeout(() => {
            tweetLinkCopied.value = false;
        }, 2000);
    });
}

</script>

<template>
    <div class="font-semibold text-sm">
        <div class="flex items-center gap-2 mb-8 ">
            <span class="text-black/50">Posted by</span>
            <img :src="tweet?.authorProfilePicUrl" class="w-6 h-auto rounded-full" alt="">
            <a href="#">@{{ tweet?.authorTwitterHandle }}</a>
        </div>

        <p class="mb-4 text-lg" v-html="tweetContent"></p>

        <div v-if="tweet?.previewImageUrls?.length" class="flex flex-wrap gap-2 my-6">
            <img v-for="image in (tweet?.previewImageUrls ?? []).slice(0, 4)" :src="image" class="rounded-2xl w-24 lg:w-32 aspect-square object-cover" alt="">
        </div>
        <div class="flex flex-wrap gap-4 justify-between items-center mb-8  text-black/50 fill-black/50">
            <button class="hover:underline" @click="copyTweetLink">
                <TimeAgo :date="tweet?.datePosted"/>
                via {{ tweet?.origin == "x" ? "X.com" : "Evangelist" }}
                <tippy to="parent" :hide-on-click="false">
                    {{tweetLinkCopied ? "Copied!" : "Copy link to clipboard"}}
                </tippy>
            </button>
            <div class="flex items-center gap-4">
                <span class="flex items-center gap-2">
                    <EyeIcon/>
                    <span>{{ tweet?.numImpressions }}</span>
                </span>
                <span class="flex items-center gap-2">
                    <IconsChatIcon/>
                    <span>{{ tweet?.numReplies }}</span>
                </span>
                <span class="flex items-center gap-2">
                    <HeartIcon/>
                    <span>{{ tweet?.numLikes }}</span>
                </span>
            </div>
        </div>

        <!-- Tweet threads -->
        <!--        <div class="border-y border-black/10 mb-8 py-6 ">-->
        <!--            <button class="flex items-center justify-between w-full text-purple">-->
        <!--                <span class="flex items-center gap-2"><ForumIcon/>post is a part of a Thread (1/8)</span>-->
        <!--                <ChevBotIcon/>-->
        <!--            </button>-->
        <!--        </div>-->

        <div class="border-t borfer-black/10 my-8"></div>
        <TweetDetailReward v-if="!loading" :tweet="tweet"/>

        <div class="my-8 format-text">
            <div v-if="state == 'evaluating'">
                <p>Evangelist algorithm is evaluating this post for its qualities, and its relevance score is being calculated. If the post reaches a relevance threshold, it will become eligible for a reward.</p>
                <p>It can take up to 24 hours. In a meanwhile you can snoop around to see more campaigns you might be interested in.</p>

                <div class="mt-8">
                    <NuxtLink to="/campaigns" class="btn bg-purple-light hover:bg-black hover:text-white">Visit All Campaigns</NuxtLink>
                </div>
            </div>
            <div v-else-if="state == 'eligible-bonus'">
                <p>This post has passed the campaign criteria and is eligible for the reward. It will appear in your balance right away, once you claim it.</p>
                <p>Claim your reward before it expires in <ExpiresIn :date="campaign?.dateEnd"/>.</p>
                <div class="mt-8">
                    <ClaimButton class="btn bg-purple-light hover:bg-black hover:text-white mb-2 lg:mb-10" :tweet="tweet" @claimTweet="getTweet"/>
                </div>
            </div>
            <div v-else-if="state == 'eligible'">
            <p>This post has passed the campaign criteria and is eligible for the reward. The final reward will be calculated based off your top 5 posts, and sent to you at the end of the campaign evaluation on {{ formatNiceDate(campaign?.dateMaturity) }}.
            </p>
                <p>Claim your reward before it expires in <ExpiresIn :date="campaign?.dateEnd"/>.</p>
                <div class="mt-8">
                    <ClaimButton class="btn bg-purple-light hover:bg-black hover:text-white mb-2 lg:mb-10" :tweet="tweet" @claimTweet="getTweet"/>
                </div>
            </div>
            <div v-else-if="state == 'not-eligible'">
                <p>This post did not pass the criteria of any ongoing campaigns so it is not eligible for a reward. Good starting point could be to visit <NuxtLink class="text-purple hover:underline" to="/campaigns">All Campaigns</NuxtLink>, pick one and follow the advertiser pointers there.</p>
                <div class="mt-8">
                    <NuxtLink to="/campaigns" class="btn bg-purple-light hover:bg-black hover:text-white">Visit All Campaigns</NuxtLink>
                </div>
            </div>
            <div v-else-if="state == 'claimed'">
                <p>This post has passed the campaign criteria and is eligible for the reward. The final reward will be calculated based off your top 5 posts, and sent to you at the end of the campaign evaluation on {{ formatNiceDate(campaign?.dateMaturity) }}.</p>
            </div>
            <div v-else-if="state == 'rewarded'">
                <p>This post has passed the campaign criteria and was eligible for the reward as one of your top 5 posts. The final reward is available for you to move to Evangelist.</p>
            </div>
            <div v-else-if="state == 'paid'">
                <p>This post has passed the campaign criteria and was eligible for the reward as one of your top 5 posts and it was already claimed.</p>
            </div>
            <div v-else-if="state == 'paid-bonus'">
                <p>This post has passed the campaign criteria and it was already claimed.</p>
            </div>
            <div v-else-if="state == 'not-rewarded'">
                <p>Although this post passed the campaign criteria, it was not your top 5 performing post within that week, so it was not rewarded.</p>
                <div class="mt-8">
                    <NuxtLink to="/campaigns" class="btn bg-purple-light hover:bg-black hover:text-white">Visit All Campaigns</NuxtLink>
                </div>
            </div>
            <div v-else-if="state == 'expired'">
                <p>The campaign has already ended without any claims on this post. Therefore it has not been included in the evaluation period.</p>
                <div class="mt-8">
                    <NuxtLink to="/campaigns" class="btn bg-purple-light hover:bg-black hover:text-white">Visit All Campaigns</NuxtLink>
                </div>
            </div>
            <div v-else-if="state == 'expired-bonus'">
                <p>This post has not been claimed in time.</p>
                <div class="mt-8">
                    <NuxtLink to="/campaigns" class="btn bg-purple-light hover:bg-black hover:text-white">Visit All Campaigns</NuxtLink>
                </div>
            </div>
        </div>
    </div>

    <div v-if="eligibleCampaigns.length" class="border-t border-black/10 py-10">
        <div class=" text-black/50 mb-8">Eligible Campaigns</div>
        <NuxtLink v-for="campaign in eligibleCampaigns" :to="'/campaign/'+campaign.id" class="block border border-black/10 bg-gradient-to-b from-white to-grey-light/20 hover:bg-gradient-to-b hover:from-white hover:to-white hover:shadow-blur rounded-2xl mb-3 p-6 pt-5 font-semibold cursor-pointer">
            <div class="text-lg mb-4 line-clamp-1">{{campaign.title}}</div>
            <CampaignFooter :campaign="campaign"/>
        </NuxtLink>
    </div>

</template>